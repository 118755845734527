@use './theme';
@use './fonts';
@use './mixins/typography';
@use './mixins/spacings';
@use './directive';

$fontFamiliy: var(--mdc-font-family, Roboto), Helvetica, Arial, sans-serif;

html {
  min-width: 320px;
  font-family: $fontFamiliy;
  --mdc-typography-button-font-family: #{$fontFamiliy};
  --mdc-typography-font-family: #{$fontFamiliy};
  --mdc-typography-subtitle2-font-family: #{$fontFamiliy};
}

.apexcharts-canvas,
text,
.apexcharts-tooltip-title,
.apexcharts-tooltip-text,
.apexcharts-legend-text,
input,
button:not(.mdc-icon-button) {
  font-family: $fontFamiliy !important;
}

.cd-layout--fixed {
  position: fixed;
}

.cd-layout--relative {
  position: relative;
}

.cd-layout--contents {
  display: contents !important;
}

.cd-layout--table-row {
  display: table-row;
}

.cd-layout--table-caption {
  display: table-caption;
}

.cd-layout--flex {
  display: flex;
}

.cd-layout--inline-grid {
  display: inline-grid;
}

.cd-layout--none {
  display: none !important;
}

.cd-layout--flex__items-center {
  align-items: center;
}

.cd-layout--flex__content-center {
  justify-content: center;
}

.cd-layout--flex__content-end {
  justify-content: flex-end;
}

.cd-layout--flex__self-end {
  justify-self: end;
}

.cd-layout--spacer {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.cd-layout--flex__end {
  justify-content: flex-end !important;
}

.cd-layout--float-right {
  float: right;
}

.cd-layout--width-100 {
  width: 100% !important;
}

.cd-layout--height-100 {
  height: 100% !important;
  &vh {
    height: 100vh !important;
  }
}

.cd-layout--max-width-300 {
  max-width: 300px;
}

.cd-pointer {
  cursor: pointer;
}

.cd-layout--pointer-none {
  pointer-events: none;
}

.cd-layout--disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.cd-layout--overflow-visible {
  overflow: visible;
}

.cd-layout--overflow-ellipsis {
  text-overflow: ellipsis;
}

.cd-layout--overflow-hidden {
  overflow: hidden;
}

.cd-layout--box {
  opacity: 0.4;

  &-selected {
    opacity: 1 !important;
  }
  &-disabled {
    opacity: 0.1 !important;
  }

  &-loading {
    opacity: 0.5 !important;
  }
}

.cd-layout--select-card {
  display: block;
  border: 2.5px solid rgba(0, 0, 0, 0);
  border-radius: 4px;

  &.cd-color-border--primary {
    mdc-card {
      border-radius: unset;
    }
  }
}

.cd-layout--box-icon-bg {
  display: block;
  border-radius: 50%;
  padding: 2px;
}

.cd-color--white {
  background-color: #fff !important;
}

.cd-color-text--white {
  color: #fff !important;
}

.cd-layout--box__kind {
  border: 2px solid;
  border-radius: 50%;
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.cd-layout--selected {
  opacity: 1;
  color: #fff;

  p,
  b {
    color: white !important;
  }

  &-not {
    opacity: 0.4;
    color: #000;
    .cd-layout--box__kind {
      border: 2px solid #000;
      mdc-icon {
        color: #000;
      }
    }
    p,
    b {
      color: #000 !important;
    }
  }
}

.cd-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Avoid browsers rendering the focus ring in some cases.
  outline: 0;

  // Avoid some cases where the browser will still render the native controls (see #9049).
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cd-layout--disabled-user-select {
  user-select: none !important;
  -webkit-user-select: none !important;
}
