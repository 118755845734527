$spacings: margin margin-left margin-right margin-top margin-bottom padding padding-top
  padding-bottom padding-right padding-left;
$spacingPixels: 0 1 2 3 4 5 6 7 8 10 12 14 15 16 18 19 20 21 22 24 26 30 32 33 36 38 40 42 46 48 52
  56 58 60 64 72 73 74 75 82 120;
@each $space in $spacings {
  @each $spacePixel in $spacingPixels {
    .cd-layout--#{$space}-#{$spacePixel} {
      #{$space}: #{$spacePixel}px !important;
    }
  }
}

$key: height min-height max-height line-height;
$keyPixels: 0 1 2 3 4 5 8 10 12 15 16 18 19 20 21 24 25 28 32 36 38 40 44 48 52 56 64 80 90 110 118
  128 135 146 160 165 180;
@each $height in $key {
  @each $keyPixel in $keyPixels {
    .cd-layout--#{$height}-#{$keyPixel} {
      #{$height}: #{$keyPixel}px !important;
    }
  }
}

.cd-layout--height-inherit {
  height: inherit;
}

.cd-layout--display__block {
  display: block;
}

.cd-layout--margin__center-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.cd-layout--margin__center {
  margin-left: auto;
  margin-right: auto;
}

.cd-layout--margin__auto {
  margin: auto !important;
}

.cd-layout--margin__bottom {
  margin-bottom: auto !important;
}

.cd-layout--margin__top {
  margin-top: auto !important;
}

.cd-layout--margin__left {
  margin-left: auto;
}

.cd-layout--margin__right {
  margin-right: auto !important;
}

.cd-layout--padding__inherit {
  padding: inherit;
}

.cd-layout--width {
  width: min-content;
}

.cd-layout--width-max {
  width: max-content;
}

.cd-layout--margin-helpertext {
  margin-top: -19px;
}

.cd-layout--align-self-center {
  align-self: center;
}
