$corner_big: 8px;
$corner_small: 4px;
$corner_tiny: 2px;

@mixin radius_big() {
  border-radius: $corner_big !important;
}

@mixin radius_small() {
  border-radius: $corner_small !important;
}

@mixin radius_tiny() {
  border-radius: $corner_tiny !important;
}

@mixin radius_big_top() {
  border-top-left-radius: $corner_big !important;
  border-top-right-radius: $corner_big !important;
}
@mixin radius_big_left() {
  border-top-left-radius: $corner_big !important;
  border-bottom-left-radius: $corner_big !important;
}
@mixin radius_big_right() {
  border-top-right-radius: $corner_big !important;
  border-bottom-right-radius: $corner_big !important;
}

@mixin radius_big_bottom() {
  border-bottom-left-radius: $corner_big !important;
  border-bottom-right-radius: $corner_big !important;
}

@mixin radius_big_small() {
  border-top-left-radius: $corner_small !important;
  border-top-right-radius: $corner_small !important;
}
