@use './material-icons';
@use './roboto';

@mixin font_base_style {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Custom Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/sorglos-icons.ttf');
  font-display: swap;
}

.cd-icons {
  font-family: 'Custom Icons';
  @include font_base_style();
}
