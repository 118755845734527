html,
body {
  padding: 0;
  margin: 0;
  height: auto;
  min-height: 100%;
  width: 100%;

  * {
    padding: 0;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
  }
}

html {
  touch-action: manipulation;
}

body {
  min-height: 100svh;
}

img {
  border-style: none;
}
