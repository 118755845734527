.mdc-badge--container {
  position: relative;
}

.mdc-badge {
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);

  &.hidden {
    display: none;
  }

  &.small {
    min-width: 18px;
    height: 18px;
    font-size: 10px;

    &.top {
      top: -7px;
    }

    &.bottom {
      bottom: -7px;
    }

    &.left {
      left: -7px;
    }

    &.right {
      right: -7px;
    }
  }

  &.medium {
    min-width: 22px;
    height: 22px;
    font-size: 11px;

    &.top {
      top: -10px;
    }

    &.bottom {
      bottom: -10px;
    }

    &.left {
      left: -10px;
    }

    &.right {
      right: -10px;
    }
  }

  &.large {
    min-width: 28px;
    height: 28px;
    font-size: 12px;

    &.top {
      top: -12px;
    }

    &.bottom {
      bottom: -12px;
    }

    &.left {
      left: -12px;
    }

    &.right {
      right: -12px;
    }
  }

  &.dot {
    min-width: 12px;
    height: 12px;
    font-size: 12px;

    &.top {
      top: -5px;
    }

    &.bottom {
      bottom: -5px;
    }

    &.left {
      left: -5px;
    }

    &.right {
      right: -5px;
    }
  }
}
