@use 'mixins/transition';
@use "@material/data-table/mixins" as data-table;
@use "@material/theme";

body {
  :not(.mdc-icon-button):not(.cd-layout--box__kind):not(.mdc-button)
  > mdc-icon:not(.mdc-deprecated-list-item__graphic):not(.mdc-select__icon):not(.mdc-fab__icon),
  b,
  a,
  h2,
  p:not(.cd-layout--selected):not(.cd-layout--snackbar-headline),
  :not(mdc-top-app-bar-section) >
  .mdc-icon-button:not(:disabled):not(.mdc-dialog__button) >
  mdc-icon {
    color: var(--mdc-theme-text-primary-on-background);
  }

  input {
    background-color: var(--mdc-theme-surface);
    color: var(--mdc-theme-text-primary-on-background);
  }
}

.mdc-button:not(.mdc-button--outlined) {
  padding: 0 15px;
}

.mdc-top-app-bar__section {
  .mdc-button--outlined .mdc-button__ripple::before, .mdc-button--outlined .mdc-button__ripple::after {
    --mdc-outlined-button-hover-state-layer-color: #000;
  }
}

.mdc-data-table {
  @include data-table.row-text-color(var(--mdc-theme-text-primary-on-background));
}

.cd-layout--font-color {
  color: var(--mdc-theme-text-primary-on-background) !important;
}

.cd-layout-disable-series {
  color: var(--mdc-theme-text-disabled-on-light) !important;
}

.cd-color-border--primary {
  border: 2.5px solid var(--mdc-theme-primary) !important;
}

.cd-socket--highlighting {
  @include transition.transition(background);
  will-change: background;
  &__active {
    background: linear-gradient(
      to bottom,
      var(--mdc-theme-primary) calc((0.2 - 1) * 10000%),
      transparent calc(0.2 * 10000%)
    );
  }
}

mdc-expansion-panel {
  .cd-layout--expansion-panel__wrapper:not(isFlat) {
    .mdc-card {
      background-color: var(--mdc-theme-on-surface--z2)
    }
  }
}
