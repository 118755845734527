/* You can add global styles to this file, and also import other style files */
@use '@material/circular-progress/mdc-circular-progress';

@use 'mixins/normalize';
@use 'core';
@use 'mixins/rounded';

@use 'mixins/transition';
/* You can add global styles to this file, and also import other style files */

body {
  &.border-radius {
    * {
      border-radius: unset !important;
    }
  }

  &.box-shadow {
    * {
      box-shadow: unset !important;
    }
  }
}

.cd-layout--radius-small {
  @include rounded.radius_small();
}

.cd-typography--CustomerNumber {
  font-size: 12px;
  font-weight: 400;
}

.cd-layout--border-card {
  border: 1px solid var(--mdc-theme-text-disabled-on-light) !important;
  border-radius: 4px;
  padding: 12px;
  &-selected {
    border: 1px solid var(--mdc-theme-secondary) !important;
  }
}

.hidden {
  display: none !important;
}

.cd-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.cd-layout--copy-wrapper:not([disabled='true']) {
  position: relative;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.cd-layout--copy-content {
  width: 52px;
  text-align: center;
  pointer-events: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  background-color: #0009;
  color: var(--mdc-theme-text-primary-on-dark, white);
  padding: 4px;
  border-radius: 4px;
  position: fixed;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 1001; // 1 more than cdk-overlay-container
  transition-property: opacity, background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &.cd-layout--copy-success {
    background-color: var(--mdc-theme-success);
    color: var(--mdc-theme-on-success);
  }

  &.cd-layout--copy-content--visible {
    opacity: 1;
  }
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label,
.apexcharts-title-text,
.apexcharts-toolbar > div > svg {
  fill: var(--mdc-theme-text-primary-on-background);
}

.apexcharts-toolbar > div:hover > svg {
  fill: var(--mdc-theme-text-primary-on-background) !important;
}

.apexcharts-legend-text {
  color: var(--mdc-theme-text-primary-on-background) !important;
}

.apexcharts-svg {
  background-color: rgba(0, 0, 0, 0) !important;
}
