@import 'material-symbols/outlined';
@import 'material-symbols/rounded';

.material-icons-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-variation-settings: 'opsz' 24;
}

.material-icons-round {
  @extend .material-icons-outlined;
  font-family: 'Material Symbols Rounded';
  font-variation-settings: 'FILL' 1, 'opsz' 24;
}

.material-icons {
  @extend .material-icons-outlined;
  font-variation-settings: 'FILL' 1, 'opsz' 24;
}
