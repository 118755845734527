$spacingPixels: 8 10 11 12 13 14 15 16 17 18 20 22 24 26 28 30 32 36 38 40 46 48;

@each $space in font-size {
  @each $spacePixel in $spacingPixels {
    .cd-layout--font-size-#{$spacePixel} {
      #{$space}: #{$spacePixel}px !important;
    }
  }
}

.cd-typography--text-center {
  text-align: center !important;
}

.cd-typography--text-left {
  text-align: left;
}

.cd-typography--text-right {
  text-align: right;
}

.cd-typography--text-end-last {
  text-align: end;
}

.cd-typography--text-justify {
  text-align: justify;
}

.cd-typography--light {
  font-weight: 100 !important;
}

.cd-typography--normal-light {
  font-weight: 300 !important;
}

.cd-typography--normal {
  font-weight: 400 !important;
}

.cd-typography--normal-strong {
  font-weight: 500 !important;
}

.cd-typography--strong {
  font-weight: 600;
}

.cd-typography--bold {
  font-weight: bold;
}

.cd-layout--letter-space {
  letter-spacing: 1px;
}

.cd-layout--text-underline {
  text-decoration: underline;
}

.cd-layout--text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cd-layout--word-break-all {
  word-break: break-all;
}

.cd-layout--line-height-initial {
  line-height: initial;
}

.cd-layout--white-space-pre {
  white-space: pre;
}
